import { createContext, Dispatch, SetStateAction } from 'react';

export interface IAppContext {
  regex: string | null;
  setRegex: (regex: string) => void;
  match: string[];
  setMatch: (match: string[]) => void;
  searchPriceRange: { min: number; max: number } | null;
  priceRange: { min: number; max: number } | null;
  setPriceRange: Dispatch<
    SetStateAction<{
      min: number;
      max: number;
    } | null>
  >;
  search: string | null;
  setSearch: (regex: string) => void;
  isSearchTriggered: boolean;
  setIsSearchTriggered: (
    regex: string,
    match: string[],
    price: { min: number; max: number } | null,
    state: boolean
  ) => void;
  metrics: {
    productCount: number;
    filterCount: number;
    notificationCount: number;
  } | null;
  isRegexValid: boolean;
  isSearchValid: boolean;
}

export const initialState: IAppContext = {
  regex: null,
  setRegex: () => {},
  match: [],
  setMatch: () => {},
  searchPriceRange: null,
  priceRange: null,
  setPriceRange: (() => {}) as any,
  search: null,
  setSearch: () => {},
  isSearchTriggered: false,
  setIsSearchTriggered: () => {},
  metrics: null,
  isRegexValid: true,
  isSearchValid: false,
};

export const AppContext = createContext<IAppContext>(initialState);
