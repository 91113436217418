// @ts-ignore
if (!window.apiUrl) {
  // @ts-ignore
  window.apiUrl = 'http://localhost:3441';
}

// @ts-ignore
const apiUrl = window.apiUrl;
const isDevelopment = apiUrl === 'http://localhost:3441';

export { apiUrl, isDevelopment };

export const getFromAPI = async (path: string) => {
  return await fetch(`${apiUrl}${path}`, {
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  }).then((res) => res.json());
};

export const postToAPI = async (path: string, payload: any) => {
  return await fetch(`${apiUrl}${path}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  }).then((res) => {
    try {
      return res.json();
    } catch {
      return res.body;
    }
  });
};

export const getApiUser = async () => {
  const { user } = await postToAPI('/auth/me', {});
  return user;
};

export const getApiStatus = async () => {
  try {
    const time = Date.now();
    const corePing = await getFromAPI('/ping');
    const elapsedTime = Date.now() - time;

    if (corePing && corePing.pong) {
      return { success: true, delay: elapsedTime };
    } else {
      throw new Error('Invalid response from API ping');
    }
  } catch (error) {
    console.error(error);
    return { success: false, delay: 0 };
  }
};
