import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { useMachine } from '@xstate/react';
import { AppContext } from './context/AppContext';
import { authMachine, AuthMachineContext } from './machines/authMachine';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Font, Color } from './Style';
import { IFilter } from '../../api/src/db/filter';
import { IProduct } from '../../api/src/db/product';

import FilterPlusIcon from 'mdi-react/FilterPlusIcon';
import CloseIcon from 'mdi-react/CloseIcon';

import { User } from './components/User';
import { Omnibar } from './components/Omnibar';
import { SearchBar } from './components/SearchBar';
import { Filter } from './components/Filter';
import { Product } from './components/Product';
import { Button } from './components/Button';
import { getFromAPI, postToAPI } from './lib/api';

const Styled = {
  Container: styled.div`
    margin: 0 auto 64px auto;
    width: 512px;
    max-width: 100%;
  `,
  Status: styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: 0;
    right: 0;
    padding: 32px 32px 0 0;
    max-height: 100%;
    box-sizing: border-box;
    z-index: 2;
    color: #fff;
  `,
  Hero: styled.div`
    padding: 100px 0 64px 0;
    text-align: center;
    color: ${Color.white};

    user-select: none;

    h1 {
      margin: 0 0 24px 0;
      font-size: 3.5em;

      small {
        position: absolute;
        margin: -1px 0 0 -26px;
        padding: 1px 3px;
        font-size: 0.18em;
        font-family: ${Font.text};
        background-color: ${Color.primary};
        border-radius: 2px;
        color: ${Color.white};
      }
    }

    p {
      margin: 0 0 16px 0;
      color: ${transparentize(0.2, Color.white)};
    }

    small {
      font-size: 0.7em;
      color: ${transparentize(0.5, Color.white)};

      b {
        color: ${transparentize(0.4, Color.white)};
      }
    }
  `,
  Control: styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 0 16px 0;

    a:first-child {
      margin-right: auto;
    }
  `,
  Incomplete: styled.div`
    width: 100%;
    min-height: 52px;
    line-height: 53px;
    text-align: center;
    background-color: ${darken(0.05, Color.black)};
    border-radius: 4px;
    color: ${Color.white};
    opacity: 0.5;

    user-select: none;
  `,
  TabGroup: styled.div`
    display: flex;
    justify-content: center;
  `,
  Tab: styled.button<{ selected?: boolean }>`
    display: inline-block;
    margin-bottom: 32px;
    padding: 8px 14px;
    border-radius: 35px;
    border: none;
    outline: none;
    background-color: ${(props) =>
      props.selected ? Color.primary : darken(0.05, Color.black)};
    color: ${Color.white};
    cursor: pointer;

    transition: box-shadow 100ms ease-out, transform 100ms ease-out;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
    }

    &:not(last-child) {
      margin-right: 8px;
    }
  `,
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Color.primary,
    },
  },
});

const numberIntl = new Intl.NumberFormat('fi-FI');

export const App: React.FC = () => {
  const [currentAuthMachine, sendToAuthMachine] = useMachine(authMachine);
  const [total, setTotal] = useState<number>(0);
  const [productList, setProductList] = useState<IProduct[]>([]);
  const [filterList, setFilterList] = useState<IFilter[]>([]);
  const [regex, setRegexRaw] = useState<string | null>(null);
  const [match, setMatch] = useState<string[]>(['name']);
  const [searchPriceRange, setSearchPriceRange] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const [priceRange, setPriceRange] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const [search, setSearchRaw] = useState<string | null>(null);
  const [metrics, setMetrics] = useState<{
    productCount: number;
    filterCount: number;
    notificationCount: number;
  } | null>(null);
  const [isSearchTriggered, setIsSearchTriggered] = useState<boolean>(false);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  const [isRegexValid, setIsRegexValid] = useState<boolean>(true);
  const [isSearchValid, setIsSearchValid] = useState<boolean>(false);

  const { user } = currentAuthMachine.context;

  const mode: string = 'regex';

  useEffect(() => {
    onPreviewRegex(regex, match, priceRange);
  }, [priceRange]);

  const loadFilterList = () => {
    getFromAPI('/filter/list').then((x) => {
      setFilterList(x);
    });
  };

  const setRegex = (value: string | null) => {
    setRegexRaw(value);

    if (!value) {
      setIsRegexValid(true);
      return;
    }

    try {
      new RegExp(value);
    } catch {
      setIsRegexValid(false);
      return;
    }

    setIsRegexValid(true);
  };

  const setSearch = (value: string | null) => {
    setSearchRaw(value);

    if (!value) {
      setIsSearchValid(false);
      return;
    }

    if (
      !value.match(/https:\/\/www\.verkkokauppa\.com\/fi\/product\/\d+\/.*/) &&
      !value.match(/^\d+$/)
    ) {
      setIsSearchValid(false);
      return;
    }

    setIsSearchValid(true);
  };

  const onPreviewRegex = (
    regex: string | null,
    match: string[],
    price: { min: number; max: number } | null = null
  ) => {
    if (!regex) return;

    postToAPI('/filter/preview', { regex, match, price }).then((x) => {
      setRegex(regex);
      setMatch(match);
      setPriceRange(price);
      setSearchPriceRange(x.price || null);
      setProductList([...x.list]);
      setTotal(parseInt(x.total));
      setIsSearchTriggered(true);
    });
  };

  const onPreviewSearch = (search: string | null) => {
    if (!search) return;

    postToAPI('/product/preview', { search }).then((product) => {
      if (!product) return;

      setSearch(search);
      setProductList([product]);
      setTotal(1);
      setIsSearchTriggered(true);
    });
  };

  const handleAddFilter = async () => {
    setIsFilterLoading(true);

    const { success } = await postToAPI('/filter/create', {
      regex,
      match,
      price: priceRange,
    });
    if (success) {
      handleClearFilter();
    }

    setIsFilterLoading(false);
  };

  const handleClearFilter = () => {
    setIsSearchTriggered(false);
    setRegex(null);
    setProductList([]);
    loadFilterList();
  };

  useEffect(() => {
    loadFilterList();

    getFromAPI('/metrics').then((x) => setMetrics(x));
  }, []);

  const metricList = metrics
    ? metrics
    : {
        productCount: 0,
        filterCount: 0,
        notificationCount: 0,
      };

  return (
    <AuthMachineContext.Provider
      value={[currentAuthMachine, sendToAuthMachine]}
    >
      <AppContext.Provider
        value={{
          regex,
          setRegex,
          match,
          setMatch,
          searchPriceRange,
          priceRange,
          setPriceRange,
          search,
          setSearch,
          metrics,
          isSearchTriggered,
          setIsSearchTriggered: (
            regex: string,
            match: string[],
            price: { min: number; max: number } | null,
            state: boolean
          ) => {
            if (state) {
              onPreviewRegex(regex, match, price || null);
            } else {
              handleClearFilter();
            }
          },
          isRegexValid,
          isSearchValid,
        }}
      >
        <ThemeProvider theme={theme}>
          <Styled.Container>
            {user && (
              <Styled.Status>
                <User
                  user={user}
                  subtext={
                    user._type === 0
                      ? 'Not authenticated with Discord ❌'
                      : 'Authenticated ✔️'
                  }
                />
              </Styled.Status>
            )}

            <Styled.Hero>
              <h1>
                <small>TAP</small>Market
              </h1>
              <p>Preview 🔍 and manage 🎛️ filters for the TAP Discord bot</p>
              <small>
                <b>{numberIntl.format(metricList.productCount)}</b> products
                tracked — <b>{numberIntl.format(metricList.filterCount)}</b>{' '}
                filters applied —{' '}
                <b>{numberIntl.format(metricList.notificationCount)}</b>{' '}
                notifications sent
              </small>
            </Styled.Hero>

            {/*
            <Styled.TabGroup>
              <Styled.Tab selected>Regex Filter</Styled.Tab>
              <Styled.Tab>Single Product</Styled.Tab>
            </Styled.TabGroup>
            */}

            {mode === 'regex' && (
              <>
                {user && user._type === 1 && (
                  <Omnibar onPreviewRegex={onPreviewRegex} />
                )}

                {!isSearchTriggered &&
                  filterList.map((x) => <Filter key={x._id} filter={x} />)}

                {isSearchTriggered && (
                  <Styled.Control>
                    <Button
                      tooltip="Clear filter preview"
                      hasReveal
                      icon={<CloseIcon />}
                      onClick={handleClearFilter}
                    />
                    <Button
                      icon={<FilterPlusIcon />}
                      onClick={handleAddFilter}
                      disabled={isFilterLoading}
                    >
                      Save as a new filter
                    </Button>
                  </Styled.Control>
                )}
                {isSearchTriggered &&
                  productList.map((x) => <Product key={x._id} product={x} />)}
                {isSearchTriggered && total > productList.length && (
                  <Styled.Incomplete>
                    And {total - productList.length}+ more
                  </Styled.Incomplete>
                )}
                {isSearchTriggered && !productList.length && (
                  <Styled.Incomplete>
                    No matching products found 😳
                  </Styled.Incomplete>
                )}
              </>
            )}

            {mode === 'product' && (
              <>
                {user && user._type === 1 && (
                  <SearchBar onPreviewSearch={onPreviewSearch} />
                )}

                {!isSearchTriggered &&
                  filterList.map((x) => <Filter key={x._id} filter={x} />)}

                {isSearchTriggered && (
                  <Styled.Control>
                    <Button
                      tooltip="Clear filter preview"
                      hasReveal
                      icon={<CloseIcon />}
                      onClick={handleClearFilter}
                    />
                    <Button
                      icon={<FilterPlusIcon />}
                      onClick={handleAddFilter}
                      disabled={isFilterLoading}
                    >
                      Save as a new filter
                    </Button>
                  </Styled.Control>
                )}
                {isSearchTriggered &&
                  productList.map((x) => <Product key={x._id} product={x} />)}
                {isSearchTriggered && total > productList.length && (
                  <Styled.Incomplete>
                    And {total - productList.length}+ more
                  </Styled.Incomplete>
                )}
                {isSearchTriggered && !productList.length && (
                  <Styled.Incomplete>
                    No matching products found 😳
                  </Styled.Incomplete>
                )}
              </>
            )}
          </Styled.Container>
        </ThemeProvider>
      </AppContext.Provider>
    </AuthMachineContext.Provider>
  );
};
