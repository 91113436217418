import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Font, Color } from '../Style';
import { AppContext } from '../context/AppContext';

import DatabaseSearchIcon from 'mdi-react/DatabaseSearchIcon';
import PlusIcon from 'mdi-react/PlusIcon';

import { Tooltip } from './Tooltip';
import { Button } from './Button';
import { RangeInput } from './RangeInput';

const Styled = {
  Container: styled.div`
    margin-bottom: 32px;
  `,
  Input: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 0 16px;
    height: 52px;
    width: 100%;
    font-size: 1.2em;
    font-family: ${Font.code};
    background-color: ${Color.white};
    border-radius: 5px;
    color: ${Color.dimgray};
    overflow: hidden;

    box-sizing: border-box;
    user-select: none;

    span {
      flex: 1;
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 44px 0 34px;
      width: calc(100% - 52px);
      height: 100%;
      border: none;
      outline: none;
      font-size: 1.2em;
      font-family: ${Font.code};
      background-color: transparent;
      color: ${Color.black};

      box-sizing: border-box;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 16px;
      width: 52px;
      height: 52px;
      /*background-color: ${transparentize(0.4, Color.gray)};*/
      color: ${Color.black};

      cursor: pointer;

      transition: background-color 200ms ease-out;

      &:hover {
        background-color: ${transparentize(0.4, Color.gray)};
      }
    }
  `,
  Options: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px 0 0 0;

    p {
      margin: 0;
      font-size: 0.8em;
      color: ${Color.dark};
    }

    .MuiFormControlLabel-root {
      height: 22px;
      user-select: none;
    }

    .MuiCheckbox-root {
      width: 16px;
      height: 16px;

      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
        fill: ${Color.dimgray};
      }
    }

    .MuiFormControlLabel-label {
      line-height: 8px;
      font-size: 0.8em;
      color: ${Color.dark};
    }

    .Mui-checked .MuiSvgIcon-root {
      fill: ${Color.primary};
    }
  `,
};

export const Omnibar: React.FC<{
  onPreviewRegex: (regex: string | null, match: string[]) => void;
}> = ({ onPreviewRegex }) => {
  const {
    regex,
    setRegex,
    match,
    setMatch,
    searchPriceRange,
    priceRange,
    setPriceRange,
    isSearchTriggered,
    isRegexValid,
  } = useContext(AppContext);

  // const handleMatchChange = (event: any, checked: boolean) => {
  //   const name = event.target.name;
  //   if (checked && !match.includes(name)) {
  //     setMatch([...match, name]);
  //   }
  //   if (!checked && match.includes(name)) {
  //     setMatch(match.filter((x) => x !== name));
  //   }
  // };

  const handlePriceRangeAdd = useCallback(
    () => setPriceRange(searchPriceRange || { min: 0, max: 0 }),
    [searchPriceRange, setPriceRange]
  );

  const handlePriceRangeChange = useCallback(
    (unit: 'min' | 'max', value: number) =>
      setPriceRange((state) => ({
        ...(state || { min: 0, max: 0 }),
        [unit]: value,
      })),
    [setPriceRange]
  );

  const handlePriceRangeRemove = useCallback(
    () => setPriceRange(null),
    [setPriceRange]
  );

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onPreviewRegex(regex, match);
    }
  };

  return (
    <Styled.Container>
      <Styled.Input>
        <input
          value={regex || ''}
          onChange={(event) => setRegex(event.target.value)}
          onKeyDown={handleKeyDown}
        />
        /<span />
        /gmi
        <Tooltip title="Preview filtered items" placement="right">
          <a onClick={() => onPreviewRegex(regex, match)}>
            <DatabaseSearchIcon />
          </a>
        </Tooltip>
      </Styled.Input>
      <Styled.Options>
        <p>
          <b>Regex</b> {isRegexValid ? 'is valid ✔️' : 'is invalid ❌'}
        </p>
        <div>
          {!priceRange && isSearchTriggered && (
            <Button icon={<PlusIcon />} small onClick={handlePriceRangeAdd}>
              Price filter
            </Button>
          )}
          {!!priceRange && isSearchTriggered && (
            <RangeInput
              value={priceRange}
              onChange={handlePriceRangeChange}
              onRemove={handlePriceRangeRemove}
            />
          )}
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!!match.includes('name')}
                  onChange={handleMatchChange}
                  name="name"
                />
              }
              labelPlacement="start"
              label={
                <>
                  Match <b>name</b>
                </>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!!match.includes('description')}
                  onChange={handleMatchChange}
                  name="description"
                />
              }
              labelPlacement="start"
              label={
                <>
                  Match <b>description</b>
                </>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!!match.includes('features')}
                  onChange={handleMatchChange}
                  name="features"
                />
              }
              labelPlacement="start"
              label={
                <>
                  Match <b>features</b>
                </>
              }
            />
          </FormGroup> */}
        </div>
      </Styled.Options>
    </Styled.Container>
  );
};
