import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { darken, lighten, transparentize } from 'polished';
import { Font, Color } from '../Style';
import { postToAPI } from '../lib/api';
import { IFilter } from '../../../api/src/db/filter';
import { AppContext } from '../context/AppContext';
import { Button } from './Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import BellRingIcon from 'mdi-react/BellRingIcon';
import BellOutlineIcon from 'mdi-react/BellOutlineIcon';
import DatabaseSearchIcon from 'mdi-react/DatabaseSearchIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { AuthMachineContext } from '../machines/authMachine';

const Styled = {
  Card: styled.div`
    position: relative;
    margin: 0 0 16px 0;
    padding: 16px 60px 16px 16px;
    background-color: ${darken(0.05, Color.black)};
    /*box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);*/
    border-radius: 4px;
    /*border-left: 4px solid ${Color.primary};*/

    box-sizing: border-box;
    user-select: none;

    > div:first-of-type {
      width: 100%;
    }

    h2 {
      margin: 0 0 14px 0;
      font-size: 1.6em;
      font-family: ${Font.code};
      color: ${Color.white};
      user-select: all;

      overflow-wrap: break-word;
      word-wrap: break-word;

      span {
        font-weight: normal;
        opacity: 0.5;
        user-select: none;

        &:first-child {
          margin-right: 4px;
        }

        &:last-child {
          margin-left: 4px;
        }
      }
    }

    .MuiFormControlLabel-root {
      height: 22px;
      user-select: none;
    }

    .MuiCheckbox-root {
      width: 16px;
      height: 16px;

      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
        fill: ${Color.dimgray};
      }
    }

    .MuiFormControlLabel-label.Mui-disabled {
      line-height: 8px;
      font-size: 0.8em;
      color: ${Color.dark};
    }

    .Mui-checked .MuiSvgIcon-root {
      fill: ${Color.primary};
    }
  `,
  Filters: styled.ul`
    margin: 0;
    padding: 0;
    min-height: 44px;
    list-style: none;
    color: ${transparentize(0.5, Color.white)};

    > li > span {
      display: inline-block;
      margin-left: 2px;
      padding: 0 2px;
      background-color: ${Color.white};
      border-radius: 4px;
      color: ${Color.dark};

      b {
        color: ${Color.black};
      }
    }
  `,
  Control: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    top: 0;
    right: 0;
    padding: 8px;
    height: 100%;

    box-sizing: border-box;

    a {
      width: 52px;
    }

    > div {
      width: auto;
      height: 52px;

      svg {
        color: ${Color.dark};
      }
    }
  `,
};

const numberIntl = new Intl.NumberFormat('fi-FI');

export const Filter: React.FC<{ filter: IFilter }> = ({ filter }) => {
  const [currentAuthMachine] = useContext(AuthMachineContext);
  const { setIsSearchTriggered } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { regex, match, price } = filter;

  const { user } = currentAuthMachine.context;

  const handleFilterClick = () => {
    setIsSearchTriggered(regex, match, price, true);
  };

  const handleRemoveFilter = async () => {
    setIsLoading(true);

    const { success } = await postToAPI('/filter/remove', { _id: filter._id });
    if (success) {
      setIsSearchTriggered('', [], null, false);
    }

    setIsLoading(false);
  };

  const handleToggleFilter = async () => {
    setIsLoading(true);

    const { success } = await postToAPI('/filter/toggle', { _id: filter._id });
    if (success) {
      setIsSearchTriggered('', [], null, false);
    }

    setIsLoading(false);
  };

  return (
    <Styled.Card>
      <div>
        <h2>
          <span>/</span>
          {filter.regex}
          <span>/gmi</span>
        </h2>

        <Styled.Filters>
          {!!price && (
            <li>
              Price{' '}
              <span>
                <b>
                  {numberIntl.format(price.min)} —{' '}
                  {numberIntl.format(price.max)}
                </b>
              </span>
            </li>
          )}{' '}
        </Styled.Filters>

        {/* <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!match.includes('name')}
                disabled
                name="name"
              />
            }
            label={
              <>
                Match <b>name</b>
              </>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!match.includes('description')}
                disabled
                name="description"
              />
            }
            label={
              <>
                Match <b>description</b>
              </>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!match.includes('features')}
                disabled
                name="features"
              />
            }
            label={
              <>
                Match <b>features</b>
              </>
            }
          />
        </FormGroup> */}
      </div>
      {user && user._type === 1 && (
        <Styled.Control>
          <Button
            hasReveal
            icon={
              filter.notify.includes(user._discord) ? (
                <BellRingIcon />
              ) : (
                <BellOutlineIcon />
              )
            }
            onClick={handleToggleFilter}
            disabled={isLoading}
            tooltip="Notify me on new matches"
            tooltipLocation="right"
          />
          <div>
            <Button
              hasReveal
              icon={<DeleteIcon />}
              onClick={handleRemoveFilter}
              disabled={isLoading}
              tooltip="Remove this filter"
            />
            <Button
              hasReveal
              icon={<DatabaseSearchIcon />}
              onClick={handleFilterClick}
              tooltip="Preview filtered items"
              tooltipLocation="right"
            />
          </div>
        </Styled.Control>
      )}
    </Styled.Card>
  );
};
