import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTooltip from '@material-ui/core/Tooltip';

const color = 'rgba(0, 0, 0, 0.6)';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color,
  },
  tooltip: {
    backgroundColor: color,
  },
}));

export const Tooltip: React.FC<{
  title: string;
  placement?: any;
  children: React.ReactElement<any, any>;
}> = (props) => {
  const classes = useStylesBootstrap();
  return <MaterialTooltip arrow classes={classes} {...props} />;
};
