import React from 'react';
import styled, { css } from 'styled-components';
import { darken, transparentize } from 'polished';
import { Tooltip } from './Tooltip';
import { Color } from '../Style';

const Styled = {
  Button: styled.a<{
    hasChildren: boolean;
    hasReveal?: boolean;
    primary?: boolean;
    small?: boolean;
    disabled?: boolean;
  }>`
    position: relative;
    display: inline-flex;
    justify-content: center;
    padding: 0 0 0 ${(props) => (props.hasChildren ? '16px' : 0)};
    height: 52px;
    line-height: 53px;
    font-style: normal;
    text-decoration: none;
    background-color: ${(props) =>
      !props.hasReveal ? transparentize(0.75, Color.dark) : 'transparent'};
    border-radius: 4px;
    color: ${Color.white};
    z-index: 1;

    user-select: none;
    cursor: ${(props) => (props.disabled ? 'wait' : 'pointer')};

    transition: background-color 100ms ease-out;

    svg {
      align-self: center;
      width: 52px;
      color: ${transparentize(0.25, Color.white)};
    }

    ${(props) =>
      !props.disabled &&
      css`
        &:hover {
          background-color: ${!props.hasReveal
            ? transparentize(0.5, Color.dark)
            : 'transparent'};
        }
      `}

    ${(props) =>
      props.primary &&
      css`
        background-color: ${Color.primary};

        &:hover {
          background-color: ${darken(0.1, Color.primary)};
        }
      `}

    ${(props) =>
      props.small &&
      css`
        padding: 0 0 0 ${props.hasChildren ? '12px' : 0};
        height: 32px;
        line-height: 32px;

        svg {
          width: 34px;
          height: 20px;
        }
      `}

    ${(props) =>
      props.hasReveal
        ? css`
            &:after {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: ${transparentize(0.5, Color.dark)};
              border-radius: 4px;
              transform: scale(0.9);
              opacity: 0;
              z-index: -1;

              transition: transform 100ms ease-out, opacity 100ms ease-out;
            }

            &:hover:after {
              transform: scale(1);
              opacity: 1;
            }
          `
        : ''}
  `,
};

export const Button: React.FC<{
  tooltip?: string;
  tooltipLocation?: string;
  icon?: React.ReactNode | null;
  hasReveal?: boolean;
  primary?: boolean;
  small?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}> = ({
  children,
  tooltip = null,
  tooltipLocation = 'left',
  icon = null,
  hasReveal = false,
  primary = false,
  small = false,
  disabled = false,
  onClick = null,
}) => {
  const wrapInATooltip = (content: any) => {
    if (tooltip) {
      return (
        <Tooltip title={tooltip} placement={tooltipLocation as any}>
          {content}
        </Tooltip>
      );
    } else {
      return content;
    }
  };

  const handleClick = () => {
    if (disabled || !onClick) return;
    return onClick();
  };

  return wrapInATooltip(
    <Styled.Button
      hasReveal={hasReveal}
      hasChildren={!!children}
      primary={primary}
      small={small}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
      {icon}
    </Styled.Button>
  );
};
