import React, { useContext } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Font, Color } from '../Style';
import { AppContext } from '../context/AppContext';

import DatabaseSearchIcon from 'mdi-react/DatabaseSearchIcon';

import { Tooltip } from './Tooltip';

const Styled = {
  Container: styled.div`
    margin-bottom: 32px;
  `,
  Input: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 0 16px;
    height: 52px;
    width: 100%;
    font-size: 1.2em;
    font-family: ${Font.code};
    background-color: ${Color.white};
    border-radius: 5px;
    color: ${Color.dimgray};
    overflow: hidden;

    box-sizing: border-box;
    user-select: none;

    span {
      flex: 1;
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 8px 0 14px;
      width: calc(100% - 52px);
      height: 100%;
      border: none;
      outline: none;
      font-size: 1.2em;
      font-family: ${Font.code};
      background-color: transparent;
      color: ${Color.black};

      box-sizing: border-box;
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 16px;
      width: 52px;
      height: 52px;
      border: none;
      outline: none;
      /*background-color: ${transparentize(0.4, Color.gray)};*/
      color: ${Color.black};

      cursor: pointer;

      transition: background-color 200ms ease-out;

      &:hover {
        background-color: ${transparentize(0.4, Color.gray)};
      }
    }
  `,
  Options: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px 0 0 0;

    p {
      margin: 0;
      font-size: 0.8em;
      color: ${Color.dark};
    }

    .MuiFormControlLabel-root {
      height: 22px;
      user-select: none;
    }

    .MuiCheckbox-root {
      width: 16px;
      height: 16px;

      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
        fill: ${Color.dimgray};
      }
    }

    .MuiFormControlLabel-label {
      line-height: 8px;
      font-size: 0.8em;
      color: ${Color.dark};
    }

    .Mui-checked .MuiSvgIcon-root {
      fill: ${Color.primary};
    }
  `,
};

export const SearchBar: React.FC<{
  onPreviewSearch: (search: string | null) => void;
}> = ({ onPreviewSearch }) => {
  const { search, setSearch, isSearchValid } = useContext(AppContext);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onPreviewSearch(search);
    }
  };

  return (
    <Styled.Container>
      <Styled.Input>
        <input
          value={search || ''}
          onChange={(event) => setSearch(event.target.value)}
          onKeyDown={handleKeyDown}
        />
        <span />
        <Tooltip title="Search for product" placement="right">
          <button onClick={() => onPreviewSearch(search)}>
            <DatabaseSearchIcon />
          </button>
        </Tooltip>
      </Styled.Input>
      <Styled.Options>
        <p>
          {isSearchValid ? 'Contains a valid ID ✔️' : 'No valid ID found ❌'}
        </p>
      </Styled.Options>
    </Styled.Container>
  );
};
