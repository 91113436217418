import React from 'react';
import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { Font, Color } from '../Style';
import { apiUrl } from '../lib/api';
import { IProduct } from '../../../api/src/db/product';

const Styled = {
  Card: styled.div`
    position: relative;
    margin: 0 0 16px 0;
    padding: 16px 116px 16px 16px;
    background-color: ${darken(0.05, Color.black)};
    /*box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);*/
    border-radius: 4px;
    border-left: 4px solid ${Color.primary};

    box-sizing: border-box;
    user-select: none;
    cursor: pointer;

    transition: box-shadow 100ms ease-out, transform 100ms ease-out;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
    }

    h4 {
      margin: 0;
      font-size: 1em;
      font-family: ${Font.text};
      color: ${Color.white};
    }

    p {
      font-size: 0.8em;
      color: ${transparentize(0.2, Color.gray)};
    }

    ul {
      padding: 0 0 0 16px;
      font-size: 0.8em;
      color: ${transparentize(0.2, Color.gray)};

      li {
        margin: 0 0 3px 0;
      }
    }

    label {
      display: block;
      margin: 0 0 2px 0;
      font-size: 0.8em;
      color: ${transparentize(0.4, Color.dimgray)};
    }

    span {
      display: block;
      font-size: 1em;
      font-weight: bold;
      color: ${transparentize(0.2, Color.white)};

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  `,
  Thumbnail: styled.div`
    position: absolute;
    right: 16px;
    top: 16px;
    width: 80px;
    height: 80px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 3px;

    box-sizing: border-box;
  `,
};

const currencyIntl = new Intl.NumberFormat('fi-FI', {
  style: 'currency',
  currency: 'EUR',
});

export const Product: React.FC<{ product: IProduct }> = ({ product }) => {
  const handleCardClick = () => {
    if (product._type === 2) {
      return window.open(
        `https://www.verkkokauppa.com/fi/outlet/yksittaiskappaleet/${product._id}`,
        '_blank'
      );
    }

    window.open(product.url, '_blank');
  };

  const imageUrl = (product?.thumbnail || '').includes('https://cdn.verk.net/')
    ? product?.thumbnail
    : `${apiUrl}/media/${product._id}`;

  return (
    <Styled.Card onClick={handleCardClick}>
      <Styled.Thumbnail style={{ backgroundImage: `url(${imageUrl})` }} />

      <h4>{product.name}</h4>
      <p>{product.description}</p>
      <ul>
        {product.features.map((x, i) => (
          <li key={i}>{x}</li>
        ))}
      </ul>

      <label>Tyyppi</label>
      <span>{product._type === 1 ? 'Outlet' : 'Palautus'}</span>

      <label>Hinta</label>
      <span>{currencyIntl.format(product.price)}</span>

      {product.condition && (
        <>
          <label>Kunto</label>
          <span>{product.condition}</span>
        </>
      )}
    </Styled.Card>
  );
};
