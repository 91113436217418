import React, { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import { Font, Color } from '../Style';

import CloseIcon from 'mdi-react/CloseIcon';

import { Button } from './Button';

const Styled = {
  Container: styled.div`
    display: flex;
    align-items: center;

    > a {
      margin: 0 6px;
    }

    > span {
      margin: 0 6px;
      font-weight: bold;
      color: ${Color.white};
      opacity: 0.5;
    }
  `,
  Input: styled.div`
    position: relative;

    input {
      padding: 0 8px 0 14px;
      width: 100px;
      height: 32px;
      border: none;
      outline: none;
      font-size: 16px;
      font-family: ${Font.text};
      background-color: ${Color.white};
      border-radius: 4px;
      color: ${Color.black};

      box-sizing: border-box;
    }

    &::after {
      content: '€';
      position: absolute;
      display: block;
      top: 50%;
      right: 8px;
      color: ${Color.dimgray};

      transform: translateY(-50%);
    }
  `,
};

export const RangeInput: React.FC<{
  value: { min: number; max: number };
  onChange: (unit: 'min' | 'max', value: number) => void;
  onRemove: () => void;
}> = ({ value, onChange, onRemove }) => {
  const handleChange = useCallback(
    (unit: 'min' | 'max', event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const numberValue = parseInt(value);

      if (isNaN(numberValue)) return onChange(unit, 0);

      return onChange(unit, numberValue);
    },
    [onChange]
  );

  const handleMinChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => handleChange('min', event),
    [handleChange]
  );
  const handleMaxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => handleChange('max', event),
    [handleChange]
  );

  return (
    <Styled.Container>
      <Button icon={<CloseIcon />} small hasReveal onClick={onRemove} />

      <Styled.Input>
        <input value={value.min} onChange={handleMinChange} />
      </Styled.Input>

      <span>—</span>

      <Styled.Input>
        <input value={value.max} onChange={handleMaxChange} />
      </Styled.Input>
    </Styled.Container>
  );
};
